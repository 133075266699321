<template>
    <el-table
        :data="list"
        style="width: 100%">
        <el-table-column
            prop="order_no"
            label="订单号"
            width="180">
        </el-table-column>
        <el-table-column
            prop="productInfo"
            label="产品信息">
            <template slot-scope="{ row }">
                <div>产品：{{ row.product_name }}</div>
                <div>价格：{{row.price}}元 / {{ row.year }}年</div>
            </template>
        </el-table-column>
        <el-table-column
            prop="order_price"
            label="付款"
            width="180">
        </el-table-column>
        <el-table-column
            prop="pay_type_dsc"
            label="支付方式">
        </el-table-column>
        <el-table-column
            prop="transaction_id"
            label="微信交易流水">
        </el-table-column>
        <el-table-column
            prop="created_at"
            label="下单时间">
        </el-table-column>
    </el-table>
</template>

<script>
import {getOrderListAPI} from '../api'
export default {
    name: "orderManage",
    props: {
        uid: {
            type: [Number, String],
            default() {
                return 0
            }
        }
    },

    data() {
        return {
            list: []
        }
    },

    mounted() {
        getOrderListAPI(this.uid).then(res => {
            this.list = res.data || []
        })
    },
}
</script>

<style scoped lang="scss">

</style>
