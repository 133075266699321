<template>
    <div class="wrapper">
        <el-card class="box-card">
            <el-descriptions title="用户信息">
                <el-descriptions-item label="ID">{{ userInfo?.id }}</el-descriptions-item>
                <el-descriptions-item label="手机号">{{ userInfo?.mobile }}</el-descriptions-item>
                <el-descriptions-item label="昵称">{{ userInfo?.nickname }}</el-descriptions-item>
                <el-descriptions-item label="注册日期">{{ userInfo?.created_at }}</el-descriptions-item>
                <el-descriptions-item label="到期时间">{{ userInfo?.expire_date }}</el-descriptions-item>
                <el-descriptions-item label="会员类型">2024-10-10</el-descriptions-item>
                <el-descriptions-item label="邀请人信息">
                    {{ userInfo?.invite_user?.id }} | {{ userInfo?.invite_user?.nickname }} |
                    {{ userInfo?.invite_user?.mobile }}
                </el-descriptions-item>
                <el-descriptions-item label="代理商信息">{{ userInfo?.agent?.id }} | {{
                        userInfo?.agent?.name
                    }}
                </el-descriptions-item>
            </el-descriptions>
        </el-card>


        <el-card class="box-card" v-if="uid">
            <el-tabs v-model="activeName">
                <el-tab-pane label="产品信息" name="productInfo">
                    <product-info :uid="uid"></product-info>
                </el-tab-pane>
                <el-tab-pane label="账号管理" name="accountManage">
                    <account-manage :uid="uid"></account-manage>
                </el-tab-pane>
                <el-tab-pane label="订单管理" name="orderManage">
                    <order-manage :uid="uid"></order-manage>
                </el-tab-pane>
                <el-tab-pane label="浏览记录" name="browsingHistory">
                    <browsing-history :uid="uid"></browsing-history>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template>

<script>
import accountManage from "@/views/user/components/accountManage.vue";
import browsingHistory from "@/views/user/components/browsingHistory.vue";
import productInfo from "@/views/user/components/productInfo.vue";
import orderManage from "@/views/user/components/orderManage.vue";
import {getDetailAPI} from './api'

export default {
    name: 'Detail',
    components: {accountManage, browsingHistory, productInfo, orderManage},
    props: {
        detail: {
            type: Object,
            default: () => {
            }
        }
    },

    data() {
        return {
            activeName: 'productInfo',
            uid: 0,
            userInfo: {}
        }
    },

    mounted() {
        this.uid = this.$route.params.id

        getDetailAPI(this.uid).then(res => {
            this.userInfo = res
        })
    },

    methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
    .box-card {
        margin-bottom: 15px;
    }
}
</style>
